define("ods-app/routes/candidato/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/mixins/route/has-tabs", "ember-changeset-validations/validators", "servir-ember-utilities/validators/custom-presence", "servir-ember-utilities/validators/list-of-emails", "servir-ember-utilities/validators/dependent-presence", "ods-app/helpers/is-empresa"], function (_exports, _abstractModuleRecord, _hasTabs, _validators, _customPresence, _listOfEmails, _dependentPresence, _isEmpresa) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import validateIsCodigo from 'servir-ember-utilities/validators/is-codigo';
  var _default = _abstractModuleRecord.default.extend(_hasTabs.default, {
    modelName: 'candidato',
    indexRoute: 'candidato',
    defaultTabSubRoute: 'informacion',
    currentUser: Ember.inject.service(),
    breadcrumbsRoute: Ember.computed('recordId', function () {
      return [{
        name: 'Candidatos existentes',
        route: 'candidatos-existentes'
      }, {
        name: 'Editar',
        route: `candidato.record.${this.defaultTabSubRoute}`,
        queryParam: this.recordId
      }];
    }),
    validations: Ember.computed(function () {
      let validaciones = {
        codigo: [(0, _customPresence.default)({
          presence: true
        }) // Se comenta porque da error al guardar por el
        // formato que tiene el codigo de los candidatos.
        // validateIsCodigo(),
        ],
        primerNombre: (0, _validators.validatePresence)({
          presence: true
        }),
        primerApellido: (0, _validators.validatePresence)({
          presence: true
        }),
        fechaNacimiento: (0, _validators.validatePresence)({
          presence: true
        }),
        emails: [(0, _validators.validateLength)({
          min: 1
        }), (0, _listOfEmails.default)(), (0, _validators.validatePresence)({
          presence: true
        })],
        telefonos: [(0, _validators.validateLength)({
          min: 1
        }), (0, _validators.validatePresence)({
          presence: true
        })],
        paisVecindad: (0, _validators.validatePresence)({
          presence: true
        }),
        sexo: (0, _validators.validatePresence)({
          presence: true
        }),
        area: (0, _validators.validatePresence)({
          presence: true
        }),
        subarea: (0, _validators.validatePresence)({
          presence: true
        }),
        gradoAcademico: (0, _validators.validatePresence)({
          presence: true
        }),
        ocupacion: (0, _validators.validatePresence)({
          presence: true
        }),
        fuenteReclutamiento: (0, _validators.validatePresence)({
          presence: true
        })
      };

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM')) {
        validaciones = Object.assign(validaciones, {
          departamentoVecindad: (0, _validators.validatePresence)({
            presence: true
          }),
          municipioVecindad: (0, _validators.validatePresence)({
            presence: true
          })
        });
      }

      return validaciones;
    }),
    relatedModelsToQuery: Ember.computed(function () {
      // Se cargan solo los modelos root de las relaciones dependientes. Luego el
      // Mixin se encarga de cargar la data de las relaciones.
      let relatedModels = [{
        name: 'paises',
        modelName: 'pais'
      }, {
        name: 'areas',
        modelName: 'area'
      }, {
        name: 'fuentesReclutamiento',
        modelName: 'fuente-reclutamiento',
        queryParams: {
          filter: {
            tipo: this.constants.REQUIREMENT_SOURCE_TYPE_CANDIDATO
          }
        }
      }];

      if (!this.get('isNew') && this.get('recordId')) {
        relatedModels.push({
          name: 'aplicaciones',
          modelName: 'aplicacion',
          queryParams: {
            filter: {
              candidato: this.get('recordId')
            }
          }
        });
      }

      return relatedModels;
    }),

    model() {
      return this._super(...arguments).then(modelData => {
        // WARNING: Pre-cargar las relaciones para que los campos dependientes
        // tomen correctamente el ID de la relación de la que dependen.
        let relationships = [modelData.record.get('paisNacimiento'), modelData.record.get('departamentoNacimiento'), modelData.record.get('municipioNacimiento'), modelData.record.get('paisVecindad'), modelData.record.get('departamentoVecindad'), modelData.record.get('municipioVecindad'), modelData.record.get('area'), modelData.record.get('subarea')];
        return Ember.RSVP.all(relationships).then(() => modelData);
      });
    },

    setupController(controller
    /*, model*/
    ) {
      this._super(...arguments); // Configure validators that require the changeset to be already defined.


      this.validations['uid'] = (0, _dependentPresence.default)({
        changeset: controller.changeset,
        dependsOn: 'pasaporte'
      });
      this.validations['pasaporte'] = (0, _dependentPresence.default)({
        changeset: controller.changeset,
        dependsOn: 'uid'
      });
    }

  });

  _exports.default = _default;
});